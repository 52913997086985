import NumericInput from '@/components/NumericInput'
import AccountNumber from '@/components/form/AccountNumber'
import mixin from '@/mixins/page/deposit'

export default {
  mixins: [mixin],
  components: { NumericInput, AccountNumber },
  props: { dialogVisible: Boolean },
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')))
      } else if (parseFloat(value) < this.minLimit) {
        callback(new Error(this.$t('common.formValidation.amtLarger', { minLimit: this.minLimit })))
      } else if (parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: this.cryptoForm.currency })
          )
        )
      } else {
        callback()
      }
    }
    return {
      cryptoForm: {
        accountNumber: '',
        currency: '',
        amount: '',
        notes: '',
      },
      cryptoRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change',
          },
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur',
          },
        ],
        notes: [
          {
            pattern: /^.{0,256}$/,
            message: this.$t('common.formValidation.note256'),
            trigger: 'blur',
          },
        ],
      },
      bankHTML: '',
    }
  },
  methods: {
    setCurrency(currency) {
      this.cryptoForm.currency = currency
    },
    setAccountNumber(accountNumber) {
      this.cryptoForm.accountNumber = accountNumber
    },
    successMethod(bankHTML) {
      this.bankHTML = bankHTML
      this.$nextTick(function() {
        if (document.getElementById('AutoForm')) {
          document.getElementById('AutoForm').submit()
        } else {
          this.$emit('update:dialogVisible', false)
          this.loading = false
          this.errorMessage(this.$t('deposit.default.failed'))
        }
      })
    },
  },
}
