<template>
  <div id="">
    <div class="content_box">
      <div class="inner">
        <div class="box_card_top flex_between">
          <div>
            <h4>{{ $t('deposit.banxa.header') }}</h4>
            <div class="info_box">
              <p>{{ $t(`deposit.banxa.desc`) }}</p>
              <ul>
                <li>{{ $t(`deposit.banxa.inst1`) }}</li>
                <li>{{ $t(`deposit.banxa.inst2`) }}</li>
                <li>{{ $t(`deposit.banxa.inst3`) }}</li>
                <li>{{ $t(`deposit.banxa.inst4`) }}</li>
              </ul>
            </div>
          </div>
          <div class="width_img">
            <img src="@/assets/images/channel/banxa.png" alt />
          </div>
        </div>
        <div class="box_card_bottom">
          <div class="form_main">
            <el-form :model="cryptoForm" ref="cryptoForm" :rules="cryptoRules">
              <ul class="clearfix">
                <li class="fl">
                  <AccountNumber
                    :supportedCurrenciesList="['USD', 'EUR', 'GBP', 'CAD']"
                    @setAccountNumber="setAccountNumber"
                    @setCurrency="setCurrency"
                  ></AccountNumber>
                  <span>{{ $t('common.field.supportedAccountCurrencies') }}: USD, EUR, GBP,CAD</span>
                </li>
                <li class="fr">
                  <numeric-input
                    v-model="cryptoForm.amount"
                    :currency="cryptoForm.currency"
                    :precision="2"
                    :label="$t('common.field.amt')"
                    name="amount"
                  ></numeric-input>
                </li>
              </ul>
              <ul class="clearfix">
                <li class="fl">
                  <InputForm
                    :label="$t('common.field.imptNotes')"
                    name="applicationNotes"
                    v-model="cryptoForm.notes"
                  ></InputForm>
                </li>
              </ul>
              <div class="form_button">
                <el-button
                  class="el-button purple_button"
                  :loading="loading"
                  :disabled="loading"
                  @click="submitForm()"
                  data-testid="submit"
                >
                  {{ $t('common.button.submit') }}
                </el-button>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cryptyoMixin from '@/mixins/page/deposit/crypto_cps';
import mixin from '@/mixins/page/deposit';
import { apiBanxaCc_payment } from '@/resource';
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
export default {
  name: 'Crypto',
  mixins: [cryptyoMixin, mixin],
  components: { NumericInput, AccountNumber },

  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger_currency_type', {
              currencyType: this.$options.filters.currencyToSymbol(this.cryptoForm.currency),
              minLimit: this.minLimit
            })
          )
        );
      } else if (parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess_currency_type', {
              currencyType: this.$options.filters.currencyToSymbol(this.cryptoForm.currency),
              maxLimit: this.maxLimit,
              currency: this.cryptoForm.currency
            })
          )
        );
      } else {
        callback();
      }
    };
    return {
      maxLimit: 10000,
      cryptoForm: {
        accountNumber: '',
        currency: '',
        amount: '',
        notes: ''
      },
      cryptoRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        notes: [
          {
            pattern: /^.{0,256}$/,
            message: this.$t('common.formValidation.note256'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  mounted() {},
  methods: {
    submitForm() {
      this.$refs['cryptoForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.code == 0 && result.data.msg) {
                window.location = result.data.msg;
              } else {
                this.errorMessage(this.$t('deposit.default.failed'));
                // re-calling anti-reuse token
                this.fetchToken()
                  .then(resp => {
                    this.loading = false;
                  })
                  .catch(resp => {
                    this.loading = false;
                    this.errorMessage(this.$t('resetPassword.failed'));
                  });
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    submitDeposit() {
      return apiBanxaCc_payment(
        {
          mt4Account: this.cryptoForm.accountNumber,
          operateAmount: this.cryptoForm.amount,
          applicationNotes: this.cryptoForm.notes
        },
        this.token
      );
    },
    setCurrency(currency) {
      this.cryptoForm.currency = currency;
    },
    setAccountNumber(accountNumber) {
      this.cryptoForm.accountNumber = accountNumber;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/default.scss';
@import '@/assets/css/components/deposit/multiButton.scss';
.form_main {
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }
  .clearfix {
    margin-bottom: 40px;
  }
}
.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto !important;
  position: relative;
  .width_img {
    position: absolute;
    right: 0;
    top: -30px;
    height: 120px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
